<template>
  <div class="zt-page-content">
    <div class="page-header" style="min-width:1600px;">南康家具企业总览</div>
    <div class="zt-block1 zt-block flex" style="min-width:1600px;">
      <div class="block-item">
        <div class="item1-top item-top">
          <div class="it1-con flex flex-justify-between">
            <div class="it1-tag">
              <div v-if="item" class="num">
                <span style="background:#C2F95B;"></span>总认证企业：<b>{{item.n_comp_certified}}</b>
              </div>
            </div>
            <div class="it1-tag">
              <div v-if="item" class="num">
                <span style="background:#5BBEF9;"></span>填写资质企业：<b>{{item.n_comp_aptitude}}</b>
              </div>
            </div>
            <div class="it1-tag">
              <div v-if="item" class="num">
                <span style="background:#5B8FF9;"></span>总注册公司：<b>{{item.n_comp_all}}</b>
              </div>
            </div>
            <div class="it1-tag">
              <div v-if="item" class="num">
                <span style="background:#CCCCCC;"></span>销售公司注册：<b>{{item.n_comp_sale_all}}</b>
              </div>
            </div>
            <div class="it1-tag">
              <div v-if="item" class="num">
                <span style="background:#CCCCCC;"></span>工厂注册：<b>{{item.n_comp_factory_all}}</b>
              </div>
            </div>
            <div class="it1-tag">
              <div v-if="item" class="num">
                <span style="background:#CCCCCC;"></span>设计公司注册：<b>{{item.n_comp_design_all}}</b>
              </div>
            </div>
          </div>
          <div class="item1" id="ratio"></div>
        </div>
        <div class="item-bottom flex">
          <div class="ib-item">
            <div class="ib-item-title">7天内注册企业</div>
            <div v-if="item" class="ib-item-text">
              <span></span>{{item.n_comp_reg_in_7days}}
            </div>
          </div>
          <div class="ib-item">
            <div class="ib-item-title">30天内注册企业</div>
            <div v-if="item" class="ib-item-text">
              <span style="background:#4AC700;"></span>{{item.n_comp_reg_in_30days}}
            </div>
          </div>
        </div>
      </div>
      <div class="block-item">
        <div class="item2-top item-top flex flex-v flex-justify-between">
          <div class="it2-con flex flex-justify-between">
            <div class="flex">
              <div class="cir"></div>已上传商品企业
            </div>
            <div class="flex num">
              <div>
                <img src="../../assets/images/bureau/bureau1.png" />
              </div>
              <div v-if="item">{{item.n_comp_has_goods}}</div>
            </div>
          </div>
          <div class="it2-con flex flex-justify-between">
            <div class="flex">
              <div style="border-color:#4AC700;" class="cir"></div>已上传商品数量
            </div>
            <div class="flex num">
              <div>
                <img src="../../assets/images/bureau/bureau1.png" />
              </div>
              <div v-if="item">{{item.n_goods}}</div>
            </div>
          </div>
        </div>
        <div class="item-bottom flex">
          <div class="ib-item">
            <div class="ib-item-title">7天内上传商品数</div>
            <div v-if="item" class="ib-item-text">
              <span style="background:#00B4FF;"></span>{{item.n_goods_in_7days}}
            </div>
          </div>
          <div class="ib-item">
            <div class="ib-item-title">30天内上传商品数</div>
            <div v-if="item" class="ib-item-text">
              <span style="background:#FFAF00;"></span>{{item.n_goods_in_30days}}
            </div>
          </div>
        </div>
      </div>
      <div class="block-item">
        <div class="item3-top item-top flex flex-v flex-justify-between">
          <div class="it3-con">
            <div class="flex flex-justify-between">
              <div class="flex">
                <div style="border-color:#4AC700;" class="cir"></div>已入规企业
              </div>
              <div v-if="item" class="num">{{item.n_in_specs}}</div>
            </div>
            <div class="item-progress">
              <div v-if="item" class="text" :style="{width:(item.n_in_county_specs/item.n_in_specs)*100+'%'}"></div>
            </div>
            <div class="progress-text flex">
              <div>
                区规
                <span v-if="item">{{item.n_in_county_specs}}</span>
              </div>
              <div>
                省规
                <span v-if="item">{{item.n_in_province_specs}}</span>
              </div>
            </div>
          </div>
          <div class="it3-con flex flex-v flex-justify-between">
            <div class="flex flex-justify-between">
              <div class="flex">
                <div style="border-color:#00B4FF;" class="cir"></div>申请入规待审核
              </div>
              <div v-if="item" class="num">{{item.n_in_specs_waiting}}</div>
            </div>
            <div class="flex flex-justify-between">
              <div class="flex">
                <div class="cir"></div>未入规企业
              </div>
              <div v-if="item" class="num">{{item.n_comp_not_in_specs}}</div>
            </div>
          </div>
        </div>
        <div class="item-bottom flex">
          <div class="ib-item">
            <div class="ib-item-title">7天内入规企业</div>
            <div v-if="item" class="ib-item-text">
              <span style="background:#325ADC;"></span>{{item.n_in_specs_7days}}
            </div>
          </div>
          <div class="ib-item">
            <div class="ib-item-title">30天内入规企业</div>
            <div v-if="item" class="ib-item-text">
              <span style="background:#4AC700;"></span>{{item.n_in_specs_30days}}
            </div>
          </div>
        </div>
      </div>
      <div class="block-item">
        <div class="title">企业用电总量</div>
        <div class="desc">单位：月</div>
        <div id="bargraph"></div>
      </div>
    </div>
    <div class="zt-block2 zt-block" style="min-width:1600px;">
      <div class="zt-block2-head">各地区企业总览</div>
      <div class="zt-block2-content flex">
        <div class="map">
          <div id="allmap"></div>
        </div>
        <div class="barstreet flex-1">
          <div class="title">区域企业数据</div>
          <div class="text">数据实时更新</div>
          <div class="tag flex">
            <div class="flex">
              <div class="square"></div>
              <div>注册企业数</div>
            </div>
            <div class="flex">
              <div class="square" style="background:#5FC0F9;"></div>
              <div>认证企业</div>
            </div>
            <div class="flex">
              <div class="square" style="background:#73DEB3;"></div>
              <div>已入规企业</div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1">
              <div id="barstreet1"></div>
            </div>
            <div class="flex-1">
              <div id="barstreet2"></div>
            </div>
            <div class="flex-1">
              <div id="barstreet3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash/debounce";
import echarts from "echarts";
import "echarts-gl";
import { bureauData } from "@/service/bureau";
export default {
  components: {},
  data() {
    return {
      popVisible: false,
      popTitle: "",
      item:null, // 数据
      addrAll:[], // 地图所有地址
      addressData:[], // 柱状图地址
      n_comp_all:[], // 区域注册企业数
      n_comp_certified:[], // 区域已认证企业数
      n_in_specs:[], // 区域已入规企业数
    };
  },
  created() {
    this.addrAll = [
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '隆木乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '大坪乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '坪市乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '横市镇',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '麻双乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '十八塘乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '凤岗镇',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '三江乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '太窝乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '朱坊乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '潭东镇（赣州经济技术开发区）',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '潭口镇（赣州经济技术开发区）',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '蓉江街道',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '浮石乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[], name: '赤土畲族乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[1,2,3], name: '龙回镇',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[4,5,6,16], name: '镜坝镇',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[7], name: '龙岭镇',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[8,9], name: '东山街道',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[10], name: '唐江镇',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[11,12,13,14], name: '龙华乡',},
      { value: 0, n_comp_certified:0, n_in_specs:0, vid:[15], name: '横寨乡',},
    ];
  },
  mounted() {
    this.refreshItems();
  },
  beforeDestroy() {
    this.ratioChart.dispose();
    this.bargraph.dispose();
    this.barstreet1.dispose();
    this.barstreet2.dispose();
    this.barstreet3.dispose();
  },
  methods: {
    goto(link) {
      this.$router.push(link);
    },
    refreshItems() {
      console.log("refreshItems");
      bureauData().then(rst => {
        this.item = rst;
        // console.log(this.item);
        this.addrAll.forEach((e)=>{
          this.addressData.push(e.name.replace('（赣州经济技术开发区）',''));
          this.item.zone.forEach((n,k)=>{
            if (e.vid && e.vid.length>0) {
              for (let m = 0;m<e.vid.length;m++) {
                if (n.id == e.vid[m]) {
                  e.value+=n.n_comp_all;
                  e.n_comp_certified+=n.n_comp_certified;
                  e.n_in_specs+=n.n_in_specs;
                  // console.log(e.n_in_specs);
                }
              }
            }
          });
          this.n_comp_all.push(e.value);
          // console.log("n_comp_all",this.n_comp_all);
          this.n_comp_certified.push(e.n_comp_certified);
          // console.log("n_comp_certified",this.n_comp_certified);
          this.n_in_specs.push(e.n_in_specs);
          // console.log("n_in_specs",this.n_in_specs);
        })
        this.initRatio();
        this.initMapChart();
      }).catch(err => {
        console.log(err);
        this.$message.error(err.message);
      });
    },
    initRatio() {
      this.ratioChart = echarts.init(document.getElementById("ratio"));
      let ratioAll = [
        [0, 0, 0, this.item.n_comp_aptitude/this.item.n_comp_all, 0, 0],
        [0, 0, 0, 0, this.item.n_comp_certified/this.item.n_comp_all, 0],
      ]
      // 彩虹图
      this.ratioChart.setOption({
        angleAxis: {
          show: false,
          startAngle: 180
        },
        radiusAxis: {
          show: false,
          type: "category",
          min: 0,
          max: 2.5,
          interval: 2
        },
        polar: {},
        series: [
          {
            type: "bar",
            data: [0, 0, 1.0, 0, 0, 0],
            coordinateSystem: "polar",
            color: "#5B8FF9",
            name: "A",
            stack: "a"
          },
          {
            type: "bar",
            data: ratioAll[0],
            coordinateSystem: "polar",
            color: "#5BBEF9",
            name: "B",
            stack: "a"
          },
          {
            type: "bar",
            data: ratioAll[1],
            coordinateSystem: "polar",
            color: "#C2F95B",
            name: "C",
            stack: "a"
          },
          {
            type: "bar",
            data: [0, 0, 0, 0, 0, 2.0],
            coordinateSystem: "polar",
            color: "rgba(0, 0, 0, 0)",
            name: "D",
            stack: "a"
          }
        ]
      });
      // 柱状图
      this.bargraph = echarts.init(document.getElementById("bargraph"));
      this.bargraph.setOption({
        dataset: {
          source: [
            ["score", "kwh", "product"],
            [89.3, 58212, "1月"],
            [57.1, 78254, "2月"],
            [74.4, 41032, "3月"],
            [50.1, 12755, "4月"],
            [89.7, 20145, "5月"],
            [68.1, 79146, "6月"]
          ]
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: { containLabel: true },
        xAxis: { name: "kwh" },
        yAxis: { type: "category" },
        visualMap: {
          show: false,
          orient: "horizontal",
          left: "center",
          min: 10,
          max: 100,
          text: ["月", "kwh"],
          dimension: 0,
          inRange: {
            color: ["#5AD8A6", "#73A0FA"]
          }
        },
        series: [
          {
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true
              }
            },
            encode: {
              x: "kwh",
              y: "product"
            }
          }
        ]
      });
      this.barstreet1 = echarts.init(document.getElementById("barstreet1"));
      this.barstreet2 = echarts.init(document.getElementById("barstreet2"));
      this.barstreet3 = echarts.init(document.getElementById("barstreet3"));
      let option3 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "5%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: this.addressData.slice(0,8),
        },
        series: [
          {
            name: "注册企业数",
            type: "bar",
            data: this.n_comp_all.slice(0,8),
            color: "#5E91F9"
          },
          {
            name: "认证企业",
            type: "bar",
            data: this.n_comp_certified.slice(0,8),
            color: "#5FC0F9"
          },
          {
            name: "已入规企业",
            type: "bar",
            data: this.n_in_specs.slice(0,8),
            color: "#73DEB3"
          }
        ]
      };
      this.barstreet1.setOption(option3);
      let option4 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "5%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: this.addressData.slice(8,16),
        },
        series: [
          {
            name: "注册企业数",
            type: "bar",
            data: this.n_comp_all.slice(8,16),
            color: "#5E91F9"
          },
          {
            name: "认证企业",
            type: "bar",
            data: this.n_comp_certified.slice(8,16),
            color: "#5FC0F9"
          },
          {
            name: "已入规企业",
            type: "bar",
            data: this.n_in_specs.slice(8,16),
            color: "#73DEB3"
          }
        ]
      };
      this.barstreet2.setOption(option4);
      let option5 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "5%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: this.addressData.slice(16,24),
        },
        series: [
          {
            name: "注册企业数",
            type: "bar",
            data: this.n_comp_all.slice(16,24),
            color: "#5E91F9"
          },
          {
            name: "认证企业",
            type: "bar",
            data: this.n_comp_certified.slice(16,24),
            color: "#5FC0F9"
          },
          {
            name: "已入规企业",
            type: "bar",
            data: this.n_in_specs.slice(16,24),
            color: "#73DEB3"
          }
        ]
      };
      this.barstreet3.setOption(option5);
    },
    initMapChart() {
      this.mapChart = echarts.init(document.getElementById("allmap"));
      let uploadedDataURL = require("../../assets/data/nankang_map.json");
      echarts.registerMap("china", uploadedDataURL);
      let mapOption = {
        timeline: {
          show: false,
          axisType: "category"
        },
        baseOption: {
          geo: {
            show: true,
            map: "china",
            roam: true,
            zoom: 30,
            center: [114.95437, 25.724217],
            left:'40%',
            top:'50%',
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#4D4D4D",
                  fontSize: "12"
                }
              },
              emphasis: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                borderColor: "#fff",
                borderWidth: 1,
                areaColor: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#E2EBF2" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#E2EBF2" // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: "#fff",
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10
              },
              emphasis: {
                areaColor: "#FDD1A4",
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                        color: "#fff",
                        fontSize: "12"
                        }
                    },
                    emphasis: {
                        show: true
                    }
                },
                borderWidth: 0
              }
            }
          }
        },
        options: [
          {
            backgroundColor: "#fff",
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br/>已注册：{c}'
            },
            series: [
              //地图？
              {
                type: "map",
                map: "china",
                geoIndex: 0,
                aspectScale: 0.75, //长宽比
                showLegendSymbol: false, // 存在legend时显示
                label: {
                  normal: {
                    show: false
                  },
                  emphasis: {
                    show: false,
                    textStyle: {
                      color: "#fff"
                    }
                  }
                },
                data: this.addrAll,
                roam: true,
                itemStyle: {
                  normal: {
                    areaColor: "#031525",
                    borderColor: "#FFFFFF"
                  },
                  emphasis: {
                    areaColor: "#2B91B7"
                  }
                },
                animation: false,
              }
            ]
          }
        ]
      };
      this.mapChart.setOption(mapOption);
    }
  }
};
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.zt-block1 {
  background: transparent;
  box-shadow: none;
}
.block-item {
  width: 390px;
  padding: 30px 30px 0;
  background: #fff;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  .item-bottom {
    padding: 20px 5px;
    .ib-item {
      width: 49%;
      height: 150px;
      padding: 40px 0 40px 20px;
      background: #f6f6fa;
      border-radius: 10px;
      .ib-item-title {
        font-size: 14px;
        font-weight: bold;
        color: #4d4d4d;
      }
      .ib-item-text {
        font-size: 40px;
        color: #4d4d4d;
        text-align: left;
        position: relative;
        text-indent: 15px;
        span {
          display: inline-block;
          vertical-align: top;
          width: 5px;
          height: 31px;
          background: #325adc;
          border-radius: 1px;
          position: absolute;
          top: 16px;
          left: 0;
        }
      }
    }
    .ib-item:first-child {
      margin-right: 2%;
    }
  }
  .item-top {
    height: 300px;
    border-bottom: 1px solid #e6e6e6;
    overflow: hidden;
    position: relative;
    .num {
      font-size: 14px;
      color: #4d4d4d;
      line-height: 30px;
      position: relative;
      text-indent: 15px;
      b {
        font-size: 17px;
      }
      img {
        width: 14px;
        position: relative;
        top: -2px;
      }
    }
    .cir {
      width: 16px;
      height: 16px;
      border: 2px solid #ffaf00;
      border-radius: 50%;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
  .item1-top {
    #ratio {
      width: 300px;
      height: 300px;
      position: relative;
      top: 120px;
      left: calc(50% - 150px);
    }
    .it1-con {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .it1-tag {
      width: 50%;
      font-size: 14px;
      color: #4d4d4d;
      text-align: left;
      span {
        display: inline-block;
        vertical-align: top;
        width: 8px;
        height: 18px;
        background: #325adc;
        border-radius: 1px;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }
  }
  .item2-top {
    padding: 22px 0 60px 0;
    .it2-con {
      .num {
        width: 100px;
      }
    }
  }
  .item3-top {
    padding: 10px 0 25px 0;
    .it3-con {
      height: 102px;
      padding: 12px;
      .num {
        width: 80px;
      }
    }
    .it3-con:first-child {
      background: #f6f6fa;
      border-radius: 10px;
      > div {
        height: 30px;
        line-height: 30px;
        .cir {
          top: 7px;
        }
      }
      .item-progress {
        width: 100%;
        height: 8px;
        background: #c2f95b;
        border-radius: 8px;
        margin-top: 13px;
        .text {
          height: 100%;
          background: #7bcffb;
          border-radius: 8px;
        }
      }
      .progress-text {
        text-align: center;
        font-size: 16px;
        color: #4d4d4d;
        line-height: 36px;
        > div {
          width: 50%;
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.block-item:last-child {
  padding: 30px 15px;
  margin-right: 0px;
  .title {
    font-size: 18px;
    color: #000000;
    line-height: 25px;
    margin-bottom: 4px;
  }
  .desc {
    font-size: 12px;
    color: #808080;
    line-height: 17px;
  }
  #bargraph {
    width: 380px;
    height: 380px;
    position: relative;
    top: 40px;
    left: -30px;
  }
}
.zt-block2 {
  padding: 30px 20px;
  border-radius: 8px;
  .zt-block2-head {
    font-size: 24px;
    color: #292929;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .zt-block2-content {
    .map {
      width: 500px;
      height: 850px;
      #allmap {
        width: 100%;
        height: 100%;
      }
    }
    .barstreet {
      padding: 30px;
      background: #f6f6fa;
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        line-height: 25px;
        margin-bottom: 7px;
      }
      .text {
        font-size: 12px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 22px;
      }
      .tag {
        font-size: 12px;
        color: #8c8c8c;
        line-height: 18px;
        > div {
          margin-right: 25px;
        }
        .square {
          width: 10px;
          height: 10px;
          background: #5e91f9;
          position: relative;
          top: 5px;
          margin-right: 8px;
        }
      }
      #barstreet1 {
        width: 320px;
        height: 670px;
      }
      #barstreet2 {
        width: 320px;
        height: 670px;
      }
      #barstreet3 {
        width: 320px;
        height: 670px;
      }
    }
  }
}
</style>